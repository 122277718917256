import gql from "graphql-tag";

export const START_PICKING_ORDER = gql`
  mutation SHOPIFY_ORDER_START_PICKING($input: StartPickingInput!) {
    shopifyOrderStartPicking(input: $input) {
      shopifyOrder {
        id
        pickedAt
        pickedEmployee {
          id
          fullName
          avatarUrl
        }
      }
      errors
    }
  }
`;

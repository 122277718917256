<script>
import VxDialogView from "@/components/vx/VxDialogView";
import OrderItems from "./OrderItems";
import CustomerInfo from "./CustomerInfo";
import ShippingInfo from "./ShippingInfo";
import Timeline from "./Timeline";

import ORDER from "./OrderQuery.gql";

import { format } from "date-fns";
import { UPDATE_ORDER_STARRED } from "../UpdateOrderStarredMutation.gql";
import { hasSnackbarAccess } from "@/mixins/ui";

export default {
  name: "OrderDetailView",
  components: {
    VxDialogView,
    OrderItems,
    CustomerInfo,
    ShippingInfo,
    Timeline,
  },
  mixins: [hasSnackbarAccess],
  props: {
    orderId: {
      type: [String, Number],
      default: undefined,
    },
  },
  data: () => ({
    shopifyOrder: {},
    loading: false,
  }),
  apollo: {
    shopifyOrder: {
      query: ORDER,
      variables() {
        return {
          id: this.orderId,
        };
      },
      skip() {
        return !this.orderId;
      },
    },
  },
  computed: {
    title() {
      if (this.$apollo.loading) {
        return "Order ...";
      }

      return `Order ${this.shopifyOrder.name}`;
    },
    formattedDate() {
      var createdDate = new Date(this.shopifyOrder.createdAt);
      if (format(new Date(), "MMMMdoyyyy") == format(createdDate, "MMMMdoyyyy"))
        return "Today " + format(createdDate, "@ h:m a");
      return format(createdDate, "MMMM do, yyyy @ h:m a");
    },
  },
  methods: {
    async handleStarClick() {
      this.loading = true;

      try {
        const {
          data: {
            updateOrderStarred: { errors },
          },
        } = await this.$apollo.mutate({
          mutation: UPDATE_ORDER_STARRED,
          variables: { id: this.shopifyOrder.id },
        });

        if (errors && errors.length) {
          throw errors.join(". ");
        }
        this.loading = false;
      } catch (error) {
        this.showSnackbar({
          text: `An error occurred while starring Order ${this.shopifyOrder.name}. ${error}`,
        });
      }
    },
  },
};
</script>

<template>
  <VxDialogView
    :title="title"
    :v-size="'medium'"
    :h-size="'xsmall'"
    :retain-focus="false"
  >
    <template #toolbar-title>
      <v-toolbar-title
        class="d-flex justify-start align-center flex-shrink-1 flex-grow-1"
      >
        <div class="mr-auto">{{ title }}</div>

        <v-btn icon large :loading="loading" @click.stop="handleStarClick">
          <v-icon
            dense
            size="30"
            :color="shopifyOrder.isStarred ? 'amber lighten-2' : 'white'"
            >{{ shopifyOrder.isStarred ? "$starOn2" : "$starOff2" }}</v-icon
          >
        </v-btn>
      </v-toolbar-title>
    </template>

    <template>
      <p class="text-body-2 font-weight-regular">{{ formattedDate }}</p>

      <!-- TODO shopifyOrder.customer - should be handled differently to be 
    not undefined, or CustomerInfo should know how to handle when it is undefined.
    v-if is here only as a temporary solution -->
      <CustomerInfo
        v-if="shopifyOrder.customer"
        :customer="shopifyOrder.customer"
      />
      <ShippingInfo :shopify-order="shopifyOrder || {}" />
      <Timeline :shopify-order="shopifyOrder" />
      <v-divider class="my-6" />
      <!-- TODO shopifyOrder.lineItems - should be handled differently to be 
    not undefined, or OrderItems should know how to handle when it is undefined.
    v-if is here only as a temporary solution -->
      <OrderItems
        v-if="shopifyOrder.lineItems"
        :items="shopifyOrder.lineItems"
      />
    </template>
  </VxDialogView>
</template>

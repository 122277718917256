<script>
export default {
  name: "CustomerInfo",
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },
};
</script>

<template>
  <v-container class="pa-0">
    <p class="mb-0 text-subtitle-1 black--text">Customer</p>
    <v-row>
      <v-col>
        <p class="text-body-1">{{ customer.fullName }}</p>
      </v-col>
      <v-col class="justify-end">
        <p v-if="!!customer.phone" class="text-subtitle-2">
          {{ customer.phone }}
        </p>
        <p class="text-subtitle-2">{{ customer.email }}</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<style></style>

<script>
import get from "lodash/get";
import flow from "lodash/fp/flow";
import compact from "lodash/fp/compact";
import join from "lodash/fp/join";
import map from "lodash/fp/map";
// import compact from "lodash/compact";

// import { chain } from "lodash";

export default {
  name: "ShippingInfo",
  props: {
    shopifyOrder: {
      type: Object,
      required: true,
    },
  },
  computed: {
    json() {
      return get(get(this, "shopifyOrder.shippingLines", [{}]), 0);
    },
    title() {
      return get(this, "json.title");
    },
    code() {
      return get(this, "json.code");
    },
    address() {
      return get(this, "shopifyOrder.shippingAddress");
    },
    cityAndProvince() {
      const city = get(this, "address.city");
      const prov = get(this, "address.province");

      return flow(
        compact,
        map((s) => s.trim()),
        join(", ")
      )([city, prov]);
    },
    showShipping() {
      const regex = /curb|pickup|pick.up/i;
      if (regex.test(get(this, "json.code"))) return false;
      if (!this.address) return false;

      return true;
    },
  },
};
</script>

<template>
  <v-container class="pa-0">
    <p class="mb-0 text-subtitle-1 black--text">Shipping</p>
    <v-row>
      <v-col>
        <p v-text="code" />
      </v-col>
      <template v-if="showShipping">
        <v-col v-text="cityAndProvince" />
      </template>
    </v-row>
  </v-container>
</template>

import { SHOPIFY_ORDER } from "./PickerSelectorQueries.js";
import { START_PICKING_ORDER } from "./PickerSelectorMutations.js";

import { hasSnackbarAccess } from "@/mixins/ui";
import {
  injectActiveEmployee,
  hasActiveEmployeeIdAccess,
} from "@/mixins/employee";

import get from "lodash/get";

export default {
  inheritAttrs: false,
  mixins: [hasSnackbarAccess, injectActiveEmployee, hasActiveEmployeeIdAccess],
  props: {
    shopifyOrderId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    shopifyOrder: undefined,
    loading: false,
  }),
  apollo: {
    shopifyOrder: {
      query: SHOPIFY_ORDER,
      fetchPolicy: "cache-first",
      variables() {
        return {
          id: this.shopifyOrderId,
        };
      },
      skip() {
        return !!!this.shopifyOrderId;
      },
    },
  },
  computed: {
    pickedAt() {
      return get(this, "shopifyOrder.pickedAt");
    },
    employee() {
      return get(this, "shopifyOrder.pickedEmployee");
    },
    hasPicker() {
      return !!get(this, "employee.id");
    },
  },
  methods: {
    async start() {
      this.loading = true;

      try {
        const {
          data: {
            shopifyOrderStartPicking: { errors },
          },
        } = await this.$apollo.mutate({
          mutation: START_PICKING_ORDER,
          variables: {
            input: {
              shopifyOrderId: this.shopifyOrderId,
              employeeId: this.activeEmployeeId,
            },
          },
        });

        if (errors.length) {
          throw new Error(errors.join("; "));
        }
      } catch (error) {
        this.showSnackbar({
          text: `Error occurred while setting picker. ${error}`,
        });
      }
      this.loading = false;
    },
  },
};

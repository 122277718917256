<script>
import Base from "./PickerSelectorBase";
import EmployeeAvatar from "@/components/employees/Avatar";
// import Stopwatch from "@/components/util/Stopwatch";

import { materialDateOrTimeString } from "@/utils/datetime";

export default {
  name: "PickerSelectorDesktop",
  components: { EmployeeAvatar },
  extends: Base,
  computed: {
    rootClasses() {
      return ["picker-selector", "picker-selector--desktop"];
    },
  },
  methods: { materialDateOrTimeString },
};
</script>

<template>
  <!-- If picking hasn't begun, show a start picking button -->
  <v-btn
    v-if="!hasPicker"
    v-bind="$attrs"
    small
    :loading="loading"
    :class="[...rootClasses, `picker-selector--activator`]"
    text
    color="accent"
    @click.stop="start"
    v-on="$listeners"
    v-text="`Start picking`"
  />

  <!-- else, show the avatar and a timer -->
  <div
    v-else
    :class="[...rootClasses, `d-flex`, `align-center`]"
    v-bind="$attrs"
  >
    <EmployeeAvatar :employee-id="employee.id" size="28" v-on="$listeners" />
    <span class="ml-2 rai-data" v-text="materialDateOrTimeString(pickedAt)" />
  </div>
</template>

import gql from "graphql-tag";

export const SHOPIFY_ORDER = gql`
  query PICKER_SELECTOR($id: ID!) {
    shopifyOrder(id: $id) {
      id
      pickedAt
      pickedEmployee {
        id
        fullName
        avatarUrl
      }
    }
  }
`;

<script>
import PickerSelector from "@/components/orders/PickerSelector/PickerSelectorDesktop";

import get from "lodash/get";

export default {
  name: "OrderTimeline",
  components: { PickerSelector },
  props: {
    shopifyOrder: {
      type: Object,
      required: true,
    },
  },
  computed: {
    pickedAt() {
      return get(this, "shopifyOrder.pickedAt");
    },
    shopifyOrderId() {
      return get(this, "shopifyOrder.id");
    },
  },
};
</script>

<template>
  <v-container class="pa-0">
    <p class="mb-0 text-subtitle-1 black--text">Timeline</p>
    <v-row>
      <v-col cols="3">
        <p class="text-body-1" v-text="`Picked`" />
      </v-col>
      <v-col>
        <!-- TODO shopifyOrderId - should be handled differently to be 
    not undefined, or PickerSelector should know how to handle when it is undefined.
    v-if is here only as a temporary solution -->
        <PickerSelector
          v-if="shopifyOrderId"
          :shopify-order-id="shopifyOrderId"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

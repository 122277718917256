<script>
export default {
  name: "OrderItems",
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    drsTransIds() {
      return new Set(this.items.map((item) => item.drsSaleTransId));
    },
    groupedItems() {
      var uniqTransIds = Array.from(this.drsTransIds);
      if (uniqTransIds[0] === "0") return [this.items];
      var temp = [];
      uniqTransIds.forEach((transId) => {
        temp.push(this.items.filter((item) => item.drsSaleTransId == transId));
      });
      return temp;
    },
  },
  methods: {
    imageUrlFor(item) {
      return `/product_image/${this.$route.params.storeId}-${item.inventoryItemId}-0`;
    },
    thumbnailUrlFor(item) {
      return this.imageUrlFor(item) + `-250`;
    },
  },
};
</script>

<template>
  <v-container class="pt-0">
    <p class="text-subtitle-1 black--text">Items ({{ items.length }})</p>
    <v-row v-for="(group, i) in groupedItems" :key="i">
      <v-subheader v-if="groupedItems.length > 1">
        {{ group[0].drsSaleTransId }}
      </v-subheader>
      <v-row v-for="item in group" :key="item.id">
        <v-col cols="3">
          <v-img :src="thumbnailUrlFor(item)" width="100" />
        </v-col>
        <v-col cols="9" class="px-0">
          <p
            :class="
              ($vuetify.breakpoint.smAndDown
                ? 'subtitle-1 font-weight-bold'
                : 'title') + ' mb-0'
            "
          >
            {{ item.name }}
          </p>
          <p class="text-subtitle-1">
            SKI:
            <b>{{ item.sku }}</b>
            <v-chip
              v-if="!!item.drsSaleTransId && item.drsSaleTransId !== '0'"
              color="error"
              small
              outlined
              class="ml-2"
              v-text="'SOLD IN DRS'"
            />
          </p>
          <span>
            <p class="text-subtitle-1">
              Location:
              <b>{{ !!item.location ? item.location : "Not set" }}</b>
            </p>
          </span>
        </v-col>
      </v-row>
    </v-row>
  </v-container>
</template>

<style></style>
!
